<template>
  <div class="an-li" @click="showInfo">
    <div class="van-multi-ellipsis--l2 name">
      {{ anLiInfo.title }}
    </div>
    <div class="an-li-bu-ju">
      <div class="image" v-if="image">
        <van-image
            class="image-div"
            width="100%"
            :src="image"
        />
      </div>
      <div class="info">
        <div class="van-ellipsis gong-si-name">
          {{ anLiInfo.g_name }}
        </div>
        <div class="van-ellipsis type">
          <biao-qian :list="[anLiInfo.type_name]"/>
        </div>
        <div class="zi-xun-div">
          <div class="van-ellipsis di-zhi-name">
            {{ address_name }}
          </div>
          <div class="di-zhi text-right">
            <a href="" @click.stop.prevent="keFu.show()">免费咨询</a>
          </div>

        </div>
      </div>
    </div>

  </div>
  <lian-xi-ke-fu ref="keFu"/>
</template>


<script>
export default {
  name: 'AnLiList',
}
</script>
<script setup>
import LianXiKeFu from "../../open/LianXiKeFu";

import BiaoQian from "../../open/biaoQian";
import XEUtils from 'xe-utils'
import {ref, defineProps, computed} from 'vue'
import {useRouter} from "vue-router";
const router = useRouter()
const keFu = ref(null)
const props = defineProps({
  anLiInfo: Object,
  to: Object
})
let image = computed(() => {
  if (props.anLiInfo.image.length > 0) return props.anLiInfo.image[0].url
  return ''
})
let address_name = computed(() => {
  let val = XEUtils.slice(props.anLiInfo.address_name.split(','), 0, 2).join('-')
  return val
})

function showInfo(){
  let to = props.to
  XEUtils.set(to, 'query.ran_id', XEUtils.uniqueId('page_'))
  router.push(to)
}

</script>


<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.an-li {
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px #ecdddd;

  .an-li-bu-ju {
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-around;
    align-items: flex-start;
  }

  div {
    margin: 1px 0;
  }

  .name {
    //color: @gray-7;
    font-size: 16px;
    font-weight: bold;
  }
  .gong-si-name{
    font-size: 16px;
    //color: @gray-7;

  }

  .type {
    padding: 5px 0;
  }

  .g-name {
    color: @gray-7;
  }

  .zi-xun-div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .di-zhi-name {
      flex: 1;
      color: @gray-6;
    }

    .di-zhi {
      width: 70px;
    }
  }


  .image {
    width: 135px;
  }

  .info {
    flex: 1;
    padding-left: 10px;
    overflow-x: hidden;
    //background-color: #1b6d85;
  }
}


</style>
