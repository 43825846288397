<template>
  <div class="zhe-die" :style="{'height':div_height+'px'}" :class="{'zhan-kai':!status}">
    <div ref="zheDieId">
      <slot></slot>
    </div>
  </div>
  <div class="text-center zhe-die-btn" v-if="is_show">
    <a href="javascript:" @click="status= !status">
      <template v-if="status">
        查看更多
        <van-icon name="arrow-down"/>
      </template>
      <template v-else>
        收起
        <van-icon name="arrow-up"/>
      </template>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ZheDieList',
}
</script>
<script setup>
import {ref, defineProps, computed} from 'vue'

const zheDieId = ref(null)
const props = defineProps({
  height: String
})
let status = ref(true)

function aaa() {
  console.log(zheDieId.value.offsetHeight)
}

const div_height = computed(() => {
  return _.min([props.height, _.get(zheDieId.value, 'offsetHeight')])
})
const is_show = computed(() => {
  return _.get(zheDieId.value, 'offsetHeight') > props.height
})

</script>

<style scoped lang="less">
@import "~@/assets/basis_css_2/style/var.less";

.zhe-die {
  overflow-y: hidden;
}

.zhe-die-btn {
  //height: 20px;
  //line-height: 20px;

  padding: 10px 0;
  font-size: 16px;

  a {
    color: @gray-6;
  }

}

.zhan-kai {
  height: auto !important;
}
</style>
