<!-- 服务商-->
<template>
    <div class="prohibit-copy" oncontextmenu="return false;" style="height: 100%;"
    >
        <nav-header fixed :title="title">
            <template #right>
                <fen-xiang>
                    <a class="tal-text-zhu-se tal-flex tal-justify-center tal-items-center tal-space-x-1"
                       href="javascript:">
                        <i class="iconfont icon-fen-xiang tal-text-zhu-se tal-font-bold"></i>
                        <span>分享</span>
                    </a>
                </fen-xiang>

            </template>
        </nav-header>
        <list-page @load="getLoad" ref="xiaLa" html-nodata=" " top="45px" bottom="110px">
            <template #empty>
                <div></div>
            </template>
            <div class="body" v-if="Object.keys(caseInfo).length > 0">
                <div class="page-content">
                    <div class="lun-bo">
                        <!--查询条件-->
                        <div class="company-header">
                            <div class="company-produce">
                                <div class="company-produce_left">
                                    <van-image
                                            class="image-div"
                                            height="103"
                                            width="125"
                                            :src="caseInfo.logo"
                                    />
                                </div>
                                <div class="company-produce_right">
                                    <h4 class="company_title van-ellipsis">{{ caseInfo.g_name }}</h4>
                                    <h5 class="company_position">{{ caseInfo.php_address.name_string }}</h5>
                                    <div class="enterprise_auth">
                                        <i class="iconfont icon-anquan"></i>
                                        <span>企业认证</span>
                                    </div>
                                </div>
                            </div>
                            <div class="service_type">
                                <div class="service_type_list">
                                    <h4>{{ getFwWupingFen(caseInfo?.join_min_gid?.ping_fen) }}%</h4>
                                    <h5>服务评分 </h5>
                                </div>
                                <div class="service_type_list">
                                    <h4>{{ getFuWuZhiLiang(caseInfo.join_min_gid) }}</h4>
                                    <h5>服务数量 </h5>
                                </div>
                                <div class="service_type_list">
                                    <h4>{{ getChengJiaoEr(caseInfo.join_min_gid) }}</h4>
                                    <h5>成交金额 </h5>
                                </div>
                            </div>
                        </div>
                        <!--服务类型-->
                        <div class="service_type_container">
                            <h4 class="service_title">
                                服务类型
                            </h4>
                            <div>
                                <zhe-die-list :height="95">
                                    <biao-qian
                                            size="lg"
                                            :list="getFwType(caseInfo?.join_min_gid?.fu_wu_type)"
                                    />
                                </zhe-die-list>
                            </div>
                        </div>
                        <div class="service_type_container" v-if="intro.open_home ==='开启'">
                            <h4 class="service_title">
                                服务商简介
                            </h4>
                            <div>
                                <zhe-die-list :height="110">
                                    <div class="jian-jie"
                                         v-if="!isEmpty(intro)"
                                         v-html="echo(intro, ['content'])"
                                    >
                                    </div>
                                </zhe-die-list>
                            </div>
                        </div>
                        <div class="service_type_container" v-if="huo_qu_zi_zhi.length">
                            <h4 class="service_title">
                                资质证书
                            </h4>
                            <div>
                                <van-row gutter="10">
                                    <van-col
                                            span="8"
                                            style="margin-bottom: 10px"
                                            v-for="(vo,index) in huo_qu_zi_zhi"
                                            :key="index"
                                    >
                                        <div class="">
                                            <van-image @click="yuLanZiZhi(index)" width="100%" height="100px"
                                                       fit="contain"
                                                       :src="vo.img_src"/>
                                            <h5 class="van-multi-ellipsis--l3 text-center">{{ vo.img_name }}</h5>
                                        </div>
                                    </van-col>
                                </van-row>
                            </div>
                        </div>

                    </div>
                    <div style="background: #f5f5f5;padding: 0 15px;height: 38px;">
                        <h4 class="service_title">
                            服务案例
                        </h4>
                    </div>
                    <div class="lie-biao">
                        <!-- 列表数据-->
                        <div class="">
                            <div v-for="(item,index) in contentData" :key="index">
                                <an-li-list :to="{name:'FuWuShangInfoDetail',query:{id:item.id}}" :an-li-info="item"/>
                            </div>
                            <div class="text-center">
                                <router-link :to="{name:'FuWuDaTing'}" style="font-size: 16px">查看更多服务商
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </list-page>

        <footer-btn v-if="showBianJiBtn" url-name="EditCompanyInfo" text="编辑信息"/>
        <contact-footer-nav v-else/>
        <kuan-jie-tiao-zhuan
                @lol-click="tiaoZHuan"
                :url="`/gzhphp/fu_wu_shang/get_kuai_jie_tiao_zhuan?g_id=${$route.query.g_id}`"
        />
    </div>

</template>
<script>
//服务大厅
import NavHeader from '@/components/top/NavHeader'
import contactFooterNav from "../open/contactFooterNav";
import http from "../../api/http";
import XEUtils from "xe-utils";
import BiaoQian from "../open/biaoQian";
import {userBasis} from "@/views/user/public/Basis";
import {computed} from 'vue'
import FooterBtn from "@/components/btn/FooterBtn";
import {getChengJiaoEr, getFuWuZhiLiang, getFwType, getFwWupingFen} from "@/api/lol_cmf_gys";
import _, {get} from 'lodash'
import KuanJieTiaoZhuan from "@/components/btn/KuanJieTiaoZhuan";
import {useRoute, useRouter} from 'vue-router'
import AnLiList from "@/views/index/public/AnLiList";
import ZheDieList from "@/components/list/ZheDieList";
import ListPage from "@/components/list/ListPage";
import FenXiang from "@/components/fenXiang.vue";
import {ImagePreview} from 'vant';
import QiYeWeiXi from "@/api/QiYeWeiXi";

export default {
    name: 'FuWuShangInfo',
    components: {
        FenXiang,
        ListPage,
        ZheDieList,
        AnLiList,
        KuanJieTiaoZhuan,
        FooterBtn,
        BiaoQian,
        NavHeader,
        contactFooterNav,
    },
    setup() {
        const route = useRoute()
        const router = useRouter()
        let g_id = route.query.g_id;
        let {userInfo} = userBasis()
        //是否显示编辑按钮
        let showBianJiBtn = computed(() => {
            return g_id == userInfo?.dang_qian_g_id
        })

        function leftHuanDong() {
            console.log('left')
        }

        function rightHuanDong() {
            console.log('right')
        }


        function tiaoZHuan(item) {
            router.replace({name: 'FuWuShangInfo', query: {g_id: item.id}})
        }

        return {
            tiaoZHuan,
            showBianJiBtn,
            leftHuanDong,
            rightHuanDong
        }
    },
    data() {
        return {
            intro: {},//间接
            title: '服务商主页',
            caseInfo: {},//轮播
            specialList: [], // 专家分类
            specialList1: [], // 专家分类1
            specialList2: [], // 专家分类2
            isZheDie: true,//true折叠
            contentData: [],
            isShowMore: true,
            xiaLa: null,
            huo_qu_zi_zhi: [],//资质
            g_id: this.$route.query.g_id
        }
    },
    mounted() {
        this.getServiceDetail(this.g_id)
        this.getZiZhi()
    },
    methods: {
        echo: get,
        isEmpty: XEUtils.isEmpty,
        getFwType,
        getChengJiaoEr,
        getFuWuZhiLiang,
        getFwWupingFen,
        yuLanZiZhi(index) {
            let list = _.map(this.huo_qu_zi_zhi, n => n.img_src)
            ImagePreview({
                images: list,
                showIndex: true,
                startPosition: index,
                closeable: true
            })
        },
        getZiZhi() {
            http.get('/gzhphp/fu_wu_shang/huo_qu_zi_zhi', {
                params: {
                    g_id: this.g_id
                }
            }).then(da => {
                if (da.code === 1) {
                    this.huo_qu_zi_zhi = _.map(da.data, n => {
                        if (!_.isEmpty(n.deng_ji)) {
                            n.img_name += `——${n.deng_ji}`
                        }
                        return n
                    })
                }
            })
        },
        //案例详情
        async getServiceDetail(paramsId) {
            //获取简介
            let jiaJian = await http.get("/gzhphp/fu_wu_shang/getIntro", {
                params: {g_id: paramsId},
                load_error: false
            })
            if (jiaJian?.code === 1) {
                if (this.echo(jiaJian, ['data', 'type']) !== '供应商') {
                    //不是服务商，跳转到客户首页
                    this.$router.replace({name: 'CustomerPages', params: {g_id: paramsId}})
                    return
                }
                this.intro = jiaJian.data
            } else {
                this.$router.push({name: 'error'})
            }

            let res = await http.get('/gzhphp/fu_wu_shang/getFuWuShangInfo', {params: {id: paramsId}})
            if (res.data) {
                this.caseInfo = res.data;
                this.title = res.data.g_name;
                this.setFengXiang()
            }
        },
        setFengXiang() {
            QiYeWeiXi.fenXiang({
                title: get(this.caseInfo, 'g_name', ''),
                desc: "赶快点击进来查看详情吧！",
                link: location.href,
                imgUrl: get(this.caseInfo, 'logo', '')
            })
        },

        // 获取数据
        getLoad({num, size}) {
            let where = {
                page: num,
                data_length: size,
                g_id: this.g_id,
                order_field: 'order_list',
                order_type: 'asc'
            }
            http.get('/gzhphp/fu_wu_shang/getAnLi', {params: where, load: false}).then(res => {
                if (res.code === 1) {
                    if (num === 1) this.contentData = [];
                    this.contentData = this.contentData.concat(res.data.data);
                    this.$refs.xiaLa.endSuccess(res.data.data.length)
                }
            });
        },
        //从0开始加载页面
        updateList() {
            this.xiaLa.value.resetData()
        }
    },
    beforeRouteEnter(to, from, next) { // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
        next(vm => {
            // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
            vm.$refs.xiaLa && vm.$refs.xiaLa.beforeRouteEnter()
        })
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.xiaLa && this.$refs.xiaLa.beforeRouteLeave()
        next()
    },
}
</script>

<style scoped lang="less">
@import "../../assets/da-ting.less";

.body {
  background: white;
}

.service_title {
  border-left: 3px solid #4A4096;
  font-size: 16px;
  margin: 15px 0;
  padding-left: 5px;
  font-weight: inherit;
}

.page-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-between;
  align-items: stretch;

  .dao-hang {
    height: auto;
  }

  .service_type_container {
  }
}

.lie-biao {
  background: #f5f5f5;
}

.service_type_container {
  margin: 15px;

  .van-col.van-col--8:nth-child(3n+1) {
    text-align: left;
  }

  .van-col.van-col--8:nth-child(3n+2) {
    text-align: center;
  }

  .van-col.van-col--8:nth-child(3n) {
    text-align: right;
  }
}

.select_button {
  width: 90%;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 5px;
  background-color: white;
  color: #4A4096;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  border: 1px solid #4A4096;
}

.my-swipe .van-swipe-item {
  color: #fff;
}

.btn_active {
  color: white;
  background-color: #4A4096;
  font-size: 13px;
}

.icon-xiangxiazhanhang {
  display: inline-block;
  font-size: 12px;
}

.icon-xiangxiazhanhang.more {
  transform: rotate(90deg);
}

.icon-xiangxiazhanhang.show_down {
  transform: rotate(-90deg);
}

.body {
  position: relative;
}

/* 浮动布局*/
.become-special-fixed {
  background-color: #ef9135;
  position: fixed;
  right: 0;
  top: 50%;
  padding: 5px;
  border-radius: 8px 0 0 8px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.company-header {
  color: white;
  padding: 20px 15px 15px 15px;
  background-image: url("../../assets/images/serviceInfoBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;

  .company-produce {
    display: flex;

    .image-div {
      width: 65px !important;
      height: auto !important;
      border-radius: 6px !important;
      overflow: hidden;
    }

    .company-produce_right {
      margin-left: 10px;
      overflow-x: hidden;
    }
  }

  .service_type {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;

    .service_type_list {
      text-align: center;

      h4 {
        margin-bottom: 5px;
      }

      h5 {
        margin-top: 0;
        opacity: .85;
      }
    }
  }

  .company_title {
    font-size: 16px;
    margin: 5px 0;
    margin-top: 0;
  }

  .company_position {
    margin: 5px 0;
    opacity: .75;
  }

  .enterprise_auth {
    border: 1px solid #ef9135;
    color: #ef9135;
    border-radius: 3px;
    font-size: 12px;
    display: inline-block;
    align-items: center;
    padding: 0 5px;

    i {
      font-size: 12px;
    }
  }

}

.jian-jie {
  padding: 0 10px 10px;
}

.zhi-zi {
  background-color: #fff;
}
</style>
